import React from 'react'

import { CONTENT } from '../content'
import Hero from '../components/Blocks/Hero/Hero'
import { Layout } from '../layouts'
import Map from '../components/Map'
import SiteTitle from '../components/Blocks/SiteTitle/SiteTitle'
import Seo from '../components/seo'

const Contact = () => (
  <Layout>
    <Seo
      title={CONTENT.CONTACT.meta.title}
      description={CONTENT.CONTACT.meta.description}
    />
    <section id="contact">
      <SiteTitle />
      <Hero>
        <header>
          <h1>{CONTENT.CONTACT.GENERAL.title}</h1>
        </header>

        <div>
          {CONTENT.CONTACT.GENERAL.content.map((content, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
          ))}
        </div>
      </Hero>

      <section className="wrapper">
        <header>
          <h2>{CONTENT.CONTACT.APPOINTMENTS.title}</h2>
        </header>
        <div>
          {CONTENT.CONTACT.APPOINTMENTS.content.map((content, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
          ))}
        </div>
      </section>

      <section className="wrapper" id="contact-map">
        <Map />
      </section>
    </section>
  </Layout>
)

export default Contact
